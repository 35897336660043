import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import { Grid, Row, Col } from 'react-flexbox-grid';
import PageHeader from 'components/PageHeader';
import { Paragraph } from 'components/Typography';
import styles from './index.module.less';
import Layout from 'components/Layout';

const Page = () => (
  <Layout>
    <Helmet
      title="Contact Us"
      meta={[
        {
          name: 'description',
          content: 'Get in touch with the team.'
        }
      ]}>
      <html lang="en" />
    </Helmet>
    <PageHeader title="Contact Us" />
    <Grid fluid>
      <Row>
        <Col md={8} className={styles.main}>
          <Paragraph>If you would like the trust's assistance, please <Link to="/apply">apply online</Link></Paragraph>
          <Paragraph>If you have a separate enquiry, please contact us:</Paragraph>
          <Paragraph>
            <a href="mailto:info@ryhaven.org.nz">info@ryhaven.org.nz</a>
            <br />
            <a href="tel:+642108578617">021 085 78617</a>
          </Paragraph>
          <Paragraph>
            <strong>Ryhaven Charitable Trust</strong>
            <br />
            9 Francis Place, Te Aro, Wellington 6011
            <br />
            PO Box 9639, Marion Square, Wellington 6141
            <br />
          </Paragraph>
        </Col>
      </Row>
    </Grid>
  </Layout>
);

export default Page;
